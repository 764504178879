<template>
  <div class="news-content">
    <!-- search -->
    <div class="search-wrap">
      <div class="common-search">
        <input @keyup.enter="getSearch" v-model="search" type="text" id="search" maxlength="50" :placeholder="$t('검색어를 입력해주세요')">
        <button @click="getSearch" type="submit" class="btn-search"></button>
      </div>
    </div>
    <!-- 内容 -->
    <div class="content-wrap">
      <div class="cont-list">
        <span class="item" v-for="(item,index) in newsContentList" :key="index">
          <div @click="goDetail(item.id)">
            <span class="common-thumbnail">
              <img :src="staticUrl+item.cover" alt="">
            </span>
            <div class="info-wrap">
              <p class="title">{{item[`title_${$i18n.locale}`]}}</p>
              <p class="desc">{{item[`abstract_${$i18n.locale}`]}} </p>
              <p class="date" v-if="item.create_at">{{item.create_at.substring(0, 10)}}</p>
            </div>
          </div>
        </span>
      </div>
      <div class="cont-footer">
        <el-pagination
          v-if="pageshow1"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import request from "../../api/request"
export default {
  data () {
    return {
      currentPage:1, //初始页
      pagesize:12,    // 每页的数据
      newsContentList: [],
      staticUrl: request.staticUrl,
      total:null,//总数据
      search:'',
      pageshow1:true,
      currentPage1:1, //初始页
      pagesize1:12,    // 每页的数据
      total1:null,//总数据
      pageshow2:true
    }
  },
  mounted(){
    this.getNewsContent();
  },
  computed: {
      locale () {
          return this.$i18n.locale
      },
  },
  watch: {
      locale () {
          this.getNewsContent()
      },
  },
  methods: {
    getNewsContent(){
      let _this = this
      let search = _this.search
      request.get('/news', {
        news_type__in:'1',search:search,page:_this.currentPage,limit:_this.pagesize
        }).then(function (res) {
        if (res.status == 200) {
          _this.newsContentList = res.data.results
          _this.total = res.data.paging.total // 后端返回的总条数
          if(res.data.paging.total == 0){
            _this.pageshow1 = false
          }
        }
      })
    },
    // search
    getSearch(){
        this.currentPage = 1
        this.getNewsContent()
    },
    handleSizeChange(size) {
      this.pagesize = size
      this.getNewsContent()
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.getNewsContent()
    },
    // 详情
    goDetail(id){
      this.$router.push({
        name:'detail',
        params:{id:id}
      })
    },
  },
}
</script>
<style>
.news-content{
  margin-top: 120px;
}
.news-content .search-wrap{
  width: 384px;
  margin-left: auto;
  margin-bottom: 40px;
}
.news-content .search-wrap .common-search{
  position: relative;
}
.news-content .search-wrap .common-search input {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 1.4;
  height: 36px;
  padding: 8px 48px 8px 10px;
  border: none;
  border-bottom: 2px solid #0b0b0b;
  border-radius: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
}
.news-content .search-wrap .common-search .btn-search{
  position: absolute;
  right: 16px;
  top: 50%;
  display: block;
  width: 23px;
  height: 23px;
  background: url('../../assets/img/icn_cm_search.svg') no-repeat;
  background-size: contain;
  transform: translateY(-50%);
  border: none;
}
.news-content .content-wrap{
  max-width: 1220px;
  margin: 0 auto 80px auto;
  padding: 0 10px;
  box-sizing: border-box;
}
.news-content .content-wrap .cont-list{
 	display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
	display: -moz-box; /* Firefox 17- */
	display: -webkit-flex;/* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
	display: -moz-flex; /* Firefox 18+ */
	display: -ms-flexbox; /* IE 10 */
	display: flex;/* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.news-content .content-wrap .cont-list .item{
  display: block;
  flex: 0 0 30%;
  margin: 0 0 90px 2%;
  text-decoration: none;
  cursor: pointer;
}
.news-content .content-wrap .cont-list .item .common-thumbnail{
  overflow: hidden;
  position: relative;
  display: block;
}
.news-content .content-wrap .cont-list .item .common-thumbnail img{
  width: 100%;
  transition:transform 2s;
}
.news-content .content-wrap .cont-list .item:hover img{
  transform: scale(1.2,1.2);
  -ms-transform:scale(1.2,1.2);
  -moz-transform:scale(1.2,1.2);
  -webkit-transform:scale(1.2,1.2);
  -o-transform:scale(1.2,1.2);
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.news-content .content-wrap .cont-list .item:nth-of-type(3n-2) {
  margin-left: 0;
}
.news-content .content-wrap .cont-list .item p{
  margin-bottom: 0;
  position: relative;
  overflow: hidden;
  text-align: justify;
  word-break: break-all;
  margin-top:10px ;
}
.news-content .content-wrap .cont-list .item .info-wrap .title{
  display: block;
  font-size: 18px;
  color: #1e1e1e;
  transition: color 0.2s;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.news-content .content-wrap .cont-list .item .info-wrap .title:hover{
  color: #6a6a6a;
}
.news-content .content-wrap .cont-list .item .info-wrap .desc{
  display: -webkit-box;
  color: #6a6a6a;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.news-content .content-wrap .cont-list .item .info-wrap .date{
  font-size: 14px;
  color: #6a6a6a;
}
.news-content .cont-footer{
  margin: 0 auto 80px auto;
  padding: 0 10px;
  box-sizing: border-box;
  text-align: center;
}
.el-pager li.active{
  color: #ccc;
}
/* .el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #000;
}
.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
  background-color: #fff;
}
.el-pager li:hover{
  color:#6a6a6a;
  text-decoration: underline;
  text-underline-position: under;  
}
.el-pager li.active{
  color: #6a6a6a;
  text-decoration: underline;
  text-underline-position: under;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover{
  color: #000;
} */
@media only screen and (max-width: 767px){
  .news-content{
    margin-top: 30px;
  }
  .news-content .content-wrap .cont-list{
    flex-wrap: wrap;
  }
  .news-content .content-wrap .cont-list .item{
    flex: 0 0 100%;
    margin: 0 0 3.33rem 0;
  }
  .news-content .search-wrap .common-search{
    width: 100%;
    padding: 0 20px;
  }
  .news-content .search-wrap .common-search input{
    width: 100%;
  }
  .news-content .search-wrap .common-search .btn-search{
    right: 0px;
    padding: 0 20px;
  }
  .news-content .search-wrap{
    width: 100%;
  }
}
</style>
