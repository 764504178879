<template>
  <div class="news">    
    <!-- banner -->
    <div v-if=" fullWidth < 767">
      <Banner
        :bannerimg="staticUrl+(topBanner['cover_mobile_' + $i18n.locale]?topBanner['cover_mobile_' + $i18n.locale]:topBanner.cover_mobile)"
      >
      <p v-html="(topBanner['description_mobile_' + $i18n.locale]?topBanner['description_mobile_' + $i18n.locale]:topBanner.description_mobile) == ''? (topBanner['description_' + $i18n.locale]?topBanner['description_' + $i18n.locale]:topBanner.description) : (topBanner['description_mobile_' + $i18n.locale]?topBanner['description_mobile_' + $i18n.locale]:topBanner.description_mobile)"></p>
      </Banner>
    </div>
    <div v-else>
      <Banner
        :bannerimg="staticUrl+(topBanner['cover_' + $i18n.locale]?topBanner['cover_' + $i18n.locale]:topBanner.cover)" 
      >
      <p v-html="(topBanner['description_' + $i18n.locale]?topBanner['description_' + $i18n.locale]:topBanner.description)"></p>
      </Banner>
    </div>
    <!-- 内容 -->
     <div class="container">
      <news-content></news-content>
    </div>
  </div>
</template>

<script>
import NewsContent from './NewsContent.vue'
import Banner from '../../components/Banner'
import request from "../../api/request"
export default {
  components: {
    NewsContent,
    Banner
  },
  data() {
    return {
        topBanner: {},
      staticUrl: request.staticUrl,
      fullWidth: 0,
    };
  },
  mounted(){
    this.banners()
    this.handleResize()
  },
  methods: {
    banners(){
      let _this = this
      request.get('/banners', {
        banner_type__in:'3'
        }).then(function (res) {
        if (res.status == 200) {
            _this.topBanner = res.data.results[0]
        }
      })
    },
    handleResize (event) {
      this.fullWidth = document.documentElement.clientWidth;
    },
  },
  created(){
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style>

</style>
